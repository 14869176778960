import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { Fragment } from "react"
import Obfuscate from "react-obfuscate"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import _ from "lodash"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useState } from "react"

const Contact = ({ pageContext }) => {
    const { countryName, cities } = pageContext

    const [search, setSearch] = useState("")

    const onChangeSearch = e => {
        setSearch(e.target.value)
    }

    return (
        <Layout>
            <Seo title={countryName} />
            <div className="container py-3 py-lg-4 contact-page-container">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark d-flex justify-content-between align-items-end flex-wrap">
                        <div className="col-md-6 col-12">
                            <h1>{countryName}</h1>
                        </div>

                        {/* Removed code for Searchbar */}
                        {/* <div className="col-md-6 col-12 d-flex justify-content-md-end justify-content-start">
                            <input
                                type="text"
                                value={search}
                                onChange={onChangeSearch}
                                className="contact-search w-100"
                                placeholder="Search"
                            />
                        </div> */}
                    </div>
                </div>

                {cities.map((c, index) => {
                    const filteredPersons = c.persons.filter(p => p.name.toLowerCase().includes(search.toLowerCase()))

                    return (
                        <div key={index} className="row city-row">
                            <div className="col-12 mb-3 mb-xl-0 col-xl-3 internal-card-container">
                                <div className="card border-0 card-product ">
                                    <div className="card-body d-flex flex-column">
                                        <Fragment>
                                            {c.cityInfo.office && <h2 className="text-light ">{c.cityInfo.office} </h2>}
                                            {c.cityInfo.legalEntity && <p className="text-light ">{c.cityInfo.legalEntity}</p>}
                                            {c.cityInfo.street && (
                                                <div className="mb-2 text-light">
                                                    <div className="d-flex">
                                                        <div>
                                                        <svg width="0.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"></path></svg>

                                                        </div>
                                                        <div className="flex-grow-1">{c.cityInfo.street}</div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div>
                                                        <svg  width="0.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marker-alt" class="invisible me-2 svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"></path></svg>

                                                        </div>
                                                        <div className="flex-grow-1">
                                                            {c.cityInfo.postcode} {c.cityInfo.city}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div>
                                                        <svg  width="0.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marker-alt" class="invisible me-2 svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"></path></svg>

                                                        </div>
                                                        <div className="flex-grow-1">{c.cityInfo.country}</div>
                                                    </div>
                                                </div>
                                            )}

                                            {c.cityInfo.emails &&
                                                c.cityInfo.emails.map((email, idx) => {
                                                    return (
                                                        <div key={idx} className="mb-2 text-light">
                                                            <div className="d-flex">
                                                                <div>
                                                                <svg width="1em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>

                                                                </div>

                                                                <div className="flex-grow-1 ">
                                                                    <Obfuscate className="text-break text-light" email={email} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </Fragment>
                                    </div>
                                </div>
                            </div>

                            {/* Removed code for contact card */}
                            {/* <div className="col-12 col-xl-9">
                                {filteredPersons.length ? (
                                    <Fragment>
                                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 g-3  mb-3">
                                            {_.orderBy(filteredPersons, x => x.name).map((person, idx) => {
                                                return (
                                                    <Fragment key={idx}>
                                                        <div className="col">
                                                            <div className="card h-100">
                                                                {person.img ? (
                                                                    <GatsbyImage
                                                                        className="card-img-top mh-50"
                                                                        image={getImage(person.img)}
                                                                        alt={person.name}
                                                                    />
                                                                ) : (
                                                                    <StaticImage
                                                                        alt={person.name}
                                                                        className="card-img-top mh-50"
                                                                        src="../../images/other/person-placeholder.png"
                                                                    />
                                                                )}
                                                                <div className="card-body px-2">
                                                                    <h5 className="card-title">{person.name}</h5>

                                                                    <p className="card-text">{person.position}</p>
                                                                    {person.telNumber && (
                                                                        <div className="d-flex">
                                                                            <div>
                                                                            <svg width="1em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="phone" class="svg-inline--fa fa-phone fa-w-16 fa-fw  me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M476.5 22.9L382.3 1.2c-21.6-5-43.6 6.2-52.3 26.6l-43.5 101.5c-8 18.6-2.6 40.6 13.1 53.4l40 32.7C311 267.8 267.8 311 215.4 339.5l-32.7-40c-12.8-15.7-34.8-21.1-53.4-13.1L27.7 329.9c-20.4 8.7-31.5 30.7-26.6 52.3l21.7 94.2c4.8 20.9 23.2 35.5 44.6 35.5C312.3 512 512 313.7 512 67.5c0-21.4-14.6-39.8-35.5-44.6zM69.3 464l-20.9-90.7 98.2-42.1 55.7 68.1c98.8-46.4 150.6-98 197-197l-68.1-55.7 42.1-98.2L464 69.3C463 286.9 286.9 463 69.3 464z"></path></svg>   

                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <Obfuscate tel={person.telNumber} />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {person.email && (
                                                                        <div className="d-flex">
                                                                            <div>
                                                                            <svg width="1em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>

                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <Obfuscate
                                                                                    className="text-break text-lowercase"
                                                                                    email={person.email?.toLowerCase()}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {person.depEmail && (
                                                                        <div className="d-flex">
                                                                            <div>
                                                                            <svg width="1em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>

                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <Obfuscate
                                                                                    className="text-break text-lowercase"
                                                                                    email={person.depEmail?.toLowerCase()}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <div className="person-badges">
                                                                        {_.orderBy(person.divisions, d => d).map((d, index) => (
                                                                            <div key={d + "_" + index} className="division-badge">
                                                                                {d}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })}
                                        </div>
                                    </Fragment>
                                ) : (
                                    <div
                                        className="w-100 h-100 d-flex justify-content-center align-items-center"
                                        style={{ fontFamily: "Lato", fontWeight: 700 }}
                                    >
                                        Not Found
                                    </div>
                                )}
                            </div> */}
                        </div>
                    )
                })}
            </div>
        </Layout>
    )
}

export default Contact

{
    /* <Layout>
<Seo title="Delhi" />
<div className="container py-3 py-lg-4">
    <div className="row g-0 mb-3">
        <div className="col border-bottom border-1 border-dark">
            <h1>Delhi</h1>
        </div>
    </div>

    <div className="row">
        <div className="col-12 mb-3 mb-xl-0 col-xl-3">
            <div className="card border-0 card-product ">
                <div className="card-body d-flex flex-column">
                    {locations.map(location => {
                        return (
                            <Fragment key={location.id}>
                                <h2 className="text-light ">{location.office}</h2>
                                <p className="text-light ">{location.legalEntity}</p>
                                <div className="mb-2 text-light">
                                    <div className="d-flex">
                                        <div>
                                            <FontAwesomeIcon
                                                className="me-2"
                                                fixedWidth
                                                icon={["far", "map-marker-alt"]}
                                            />
                                        </div>
                                        <div className="flex-grow-1">{location.street}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <FontAwesomeIcon
                                                className="invisible me-2"
                                                fixedWidth
                                                icon={["far", "map-marker-alt"]}
                                            />
                                        </div>
                                        <div className="flex-grow-1">
                                            {location.postcode} {location.city}
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <FontAwesomeIcon
                                                className="invisible me-2"
                                                fixedWidth
                                                icon={["far", "map-marker-alt"]}
                                            />
                                        </div>
                                        <div className="flex-grow-1">{location.country}</div>
                                    </div>
                                </div>

                                {location.emails &&
                                    location.emails.map((email, idx) => {
                                        return (
                                            <div key={idx} className="mb-2 text-light">
                                                <div className="d-flex">
                                                    <div>
                                                        <FontAwesomeIcon
                                                            className="me-2"
                                                            fixedWidth
                                                            icon={["far", "envelope"]}
                                                        />
                                                    </div>

                                                    <div className="flex-grow-1 ">
                                                        <Obfuscate className="text-break text-light" email={email} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </Fragment>
                        )
                    })}
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-9">
            {locations.map(location => {
                return (
                    <Fragment key={location.id}>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 g-3  mb-3">
                            {_.orderBy(location.persons, x => x.name).map((person, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        <div className="col">
                                            <div className="card h-100">
                                                <GatsbyImage
                                                    className="card-img-top mh-50"
                                                    image={getImage(person.img)}
                                                    alt={person.name}
                                                />
                                                <div className="card-body px-2">
                                                    <h5 className="card-title">{person.name}</h5>

                                                    <p className="card-text">{person.position}</p>
                                                    {person.telNumber && (
                                                        <div className="d-flex">
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    className="me-2"
                                                                    fixedWidth
                                                                    icon={["far", "phone"]}
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <Obfuscate tel={person.telNumber} />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {person.email && (
                                                        <div className="d-flex">
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    className="me-2"
                                                                    fixedWidth
                                                                    icon={["far", "envelope"]}
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <Obfuscate className="text-break" tel={person.email} />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {person.depEmail && (
                                                        <div className="d-flex">
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    className="me-2"
                                                                    fixedWidth
                                                                    icon={["far", "envelope"]}
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <Obfuscate
                                                                    className="text-break"
                                                                    email={person.depEmail}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            })}
                        </div>
                    </Fragment>
                )
            })}
        </div>
    </div>
</div>
</Layout> */
}
